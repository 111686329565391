<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12">
				<DataTable :value="products" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
							<div>Total: {{products.length}}</div>
							<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="Nuevo" v-if="insert"/>
						</div>
					</template>
					<Column field="cedula" header="N° Cédula" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.cedula}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}} ({{slotProps.data.titulo}})
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="celular" header="N° Celular" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.celular}}
						</template>
					</Column>
					<Column header="Estatus" headerStyle="width: 150px">
						<template #body="slotProps">
							<span class="p-tag p-tag-success"  v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger"  v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Acciones" headerStyle="width: 180px">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="Editar(slotProps.data)" v-if="edit"/>
							<Button icon="pi pi-money-bill" class="p-button-rounded p-button-info mr-2" @click="EditarSueldo(slotProps.data)" v-if="edit"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="Confirmar(slotProps.data)" v-if="del"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Editar ID'+product.id" :modal="true" class="p-fluid" :maximizable="true">
			<div class="fluid">       
				<div class="grid fluid mt-3">
					<div class="col-2">
						<label for="name">N° Cédula</label>
						<InputNumber id="cedula" v-model="product.cedula" integeronly required="true" min="0"/>
						<small class="p-invalid" v-if="!product.cedula">Es requerido.</small>
					</div>
					<div class="col-5">
						<label for="name">Apellido</label>
						<InputText id="name" v-model="product.apellido" required="true"/>
						<small class="p-invalid" v-if="!product.apellido">Es requirido</small>
					</div>
					<div class="col-5">
						<label for="name">Nombre</label>
						<InputText id="name" v-model.trim="product.nombre" required="true"/>
						<small class="p-invalid" v-if="!product.nombre">Es requirido</small>
					</div>
					<div class="col-2">
						<label for="name">Sexo</label>
						<Dropdown v-model="product.sexo" :options="$store.state.sexos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
						<small class="p-invalid" v-if="!product.nombre">Es requirido</small>
					</div>
					<div class="col-4">
						<label for="name">RIF</label>
						<InputText id="rif" v-model="product.rif"/>
					</div>
					<div class="col-3">
						<label for="name">Fecha Nac.</label>
						<InputText id="fecha"  v-model="product.fechnac" type="date"/>
						<small class="p-invalid" v-if="!product.fechnac">Es requirido</small>
					</div>
					<div class="col-3">
						<label>Telf Celular</label>
						<InputMask v-model="product.celular" mask="(9999) 999-9999" />
						<small class="p-invalid" v-if="!product.celular">Es requerido.</small>
					</div>
					<div class="col-9">
						<label for="name">Correo</label>
						<InputText id="name" v-model.trim="product.correo" required="true"/>
						<small class="p-invalid" v-if="!product.correo">Es requerido.</small>
					</div>
					<div class="col-3">
						<h6>Estatus</h6>
						<InputSwitch v-model="product.activo"  style="margin-top: 0"/>
					</div>
					<div class="col-6">
						<label for="name">Cargo</label>
						<InputText id="name" v-model="product.cargo" required="true"/>
						<small class="p-invalid" v-if="!product.cargo">Es requirido</small>
					</div>
					<div class="col-3">
						<label for="name">Fecha Ingreo</label>
						<InputText id="fecha"  v-model="product.fech_ingreso" type="date"/>
						<small class="p-invalid" v-if="!product.fech_ingreso">Es requirido</small>
					</div>
					<div class="col-3" v-if="!product.activo">
						<label for="name">Fecha Egreso</label>
						<InputText id="fecha"  v-model="product.fech_egreso" type="date" :min="product.fech_ingreso"/>
						<small class="p-invalid" v-if="!product.fech_egreso">Es requirido</small>
					</div>
				</div>
                <h5>Dirección</h5>			
				<div class="grid fluid mt-3">
					<div class="col-6">
						<label for="name">Estado</label>
						<Dropdown v-model="product.estado" :options="$store.state.estados" optionLabel="nombre" placeholder="Selecciona..." @change="Ciudades(product.estado.id)"></Dropdown>
						<small class="p-invalid" v-if="!product.estado">Es requirido</small>
					</div>
					<div class="col-6">
						<label for="name">Ciudad</label>
						<Dropdown v-model="product.ciudad" :options="ciudades" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
						<small class="p-invalid" v-if="!product.ciudad">Es requirido</small>
					</div>
					<div class="col-12">
						<label>Dirección Detallada</label>
						<Textarea v-model="product.direccion"  rows="3"/>						
						<small class="p-invalid" v-if="!product.direccion">Es requirido</small>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="deleteDialog"  style="width: 30vw" :header="'Eliminar ID'+product.id" :modal="true">
            <div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres eliminar?</span>
			</div>
			<template #footer>
				<Button label="Si" icon="pi pi-check" class="p-button-secondary" @click="Eliminar" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="productDialog2" :style="{width: size}" :header="'Sueldo ID'+product.id" :modal="true" class="p-fluid" :maximizable="true">
			<div class="fluid">    
				
				<div class="grid fluid mt-3">
					<div class="col-3">
						<label for="name">N° Cédula: </label>
						<strong>{{product.cedula}}</strong>
					</div>
					<div class="col-9">
						<label for="name">Nombre y Apellido: </label>
						<strong>{{product.nombre}} {{product.apellido}}</strong>
					</div>
				</div>	
				<div class="grid fluid mt-3">
					<div class="col-3">
						<label for="name">Moneda</label>
						<Dropdown v-model="product.moneda" :options="this.$store.state.monedas" optionLabel="nombre" placeholder="Selecciona..." @change="Sueldo()"></Dropdown>
					</div>
					<div class="col-3">
						<label for="name">Ciclo de Pago</label>
						<Dropdown v-model="product.ciclo" :options="ciclos" optionLabel="nombre" placeholder="Selecciona..." @change="Sueldo()"></Dropdown>
					</div>
					<div class="col-3" v-if="product.moneda">
						<label for="price">Sueldo</label>
						<InputNumber v-model="product.sueldo" mode="currency" :currency="product.moneda.code" locale="en-US" disabled/>
					</div>
					<div class="col-3" v-if="product.moneda">
						<label for="price">Bonificación</label>
						<InputNumber v-model="product.bono" mode="currency" :currency="product.moneda.code" locale="en-US" disabled/>
					</div>
				</div>
				<div class="grid fluid mt-3" v-if="product.moneda && product.ciclo">
					<div class="col-3" v-if="product.ciclo.id>0">
						<label for="price">Sueldo Sem 01</label>
						<InputNumber v-model="product.sem1" mode="currency" :currency="product.moneda.code" locale="en-US" @change="Sueldo()"/>
					</div>
					<div class="col-3" v-if="product.ciclo.id>1">
						<label for="price">Sueldo Sem 02</label>
						<InputNumber v-model="product.sem2" mode="currency" :currency="product.moneda.code" locale="en-US" @change="Sueldo()"/>
					</div>
					<div class="col-3" v-if="product.ciclo.id>2">
						<label for="price">Sueldo Sem 03</label>
						<InputNumber v-model="product.sem3" mode="currency" :currency="product.moneda.code" locale="en-US" @change="Sueldo()"/>
					</div>
					<div class="col-3" v-if="product.ciclo.id>2">
						<label for="price">Sueldo Sem 04</label>
						<InputNumber v-model="product.sem4" mode="currency" :currency="product.moneda.code" locale="en-US" @change="Sueldo()"/>
					</div>
				</div>
				<div class="grid fluid mt-3" v-if="product.moneda && product.ciclo">
					<div class="col-3" v-if="product.ciclo.id>0">
						<label for="price">Bono Sem 01</label>
						<InputNumber v-model="product.semb1" mode="currency" :currency="product.moneda.code" locale="en-US" @change="Sueldo()"/>
					</div>
					<div class="col-3" v-if="product.ciclo.id>1">
						<label for="price">Bono Sem 02</label>
						<InputNumber v-model="product.semb2" mode="currency" :currency="product.moneda.code" locale="en-US" @change="Sueldo()"/>
					</div>
					<div class="col-3" v-if="product.ciclo.id>2">
						<label for="price">Bono Sem 03</label>
						<InputNumber v-model="product.semb3" mode="currency" :currency="product.moneda.code" locale="en-US" @change="Sueldo()"/>
					</div>
					<div class="col-3" v-if="product.ciclo.id>2">
						<label for="price">Bono Sem 04</label>
						<InputNumber v-model="product.semb4" mode="currency" :currency="product.moneda.code" locale="en-US" @change="Sueldo()"/>
					</div>
				</div>
			</div>
			<template #footer>
				<h2>
					<strong>Sueldo Total: {{product.total}}</strong>
				</h2>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="GuardarSueldo()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog2 = false"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('RRHH','Personal');

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
				productDialog: false,
				productDialog2: false,
				deleteDialog: false,
				selectedCustomers: null,
				filters: {},
				size: '60vw',
				roles: [],
                ciudades:[],
				ciclos: []
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.products = response.result.personal;
					this.ciclos = response.result.ciclo;
					this.$store.commit('Loading');
				}); 
			},
			Nuevo() {
				this.nuevo = true;
				this.product = {};
                this.product.id = '';
				this.product.activo = true;
				this.product.sem1 = 0;
				this.product.sem2= 0;
				this.product.sem3 = 0;
				this.product.sem4 = 0;
				this.product.sueldo = 0;
				this.product.semb1 = 0;
				this.product.semb2= 0;
				this.product.semb3 = 0;
				this.product.semb4 = 0;
				this.product.bono = 0;
				this.product.total = 0;
				this.product.ciclo = null;
				this.product.moneda = null;
				this.productDialog = true;
			},
			Ciudades(estado) {
                this.ciudades =  this.$store.state.ciudades.filter(val => val.estado == estado);
			},
			Sueldo() {
				if(this.product.ciclo.id==1){
					this.product.sem2 = 0;
					this.product.sem3 = 0;
					this.product.sem4 = 0;
					this.product.semb2 = 0;
					this.product.semb3 = 0;
					this.product.semb4 = 0;
				}
				if(this.product.ciclo.id==2){
					this.product.sem3 = 0;
					this.product.sem4 = 0;
					this.product.semb3 = 0;
					this.product.semb4 = 0;
				}
                this.product.sueldo = parseFloat(this.product.sem1) + parseFloat(this.product.sem2) 
					+ parseFloat(this.product.sem3) + parseFloat(this.product.sem4);
				this.product.bono = parseFloat(this.product.semb1) + parseFloat(this.product.semb2) 
					+ parseFloat(this.product.semb3) + parseFloat(this.product.semb4);
				this.product.total = parseFloat(this.product.sueldo) + parseFloat(this.product.bono);
			},
			Editar(product) {
				this.nuevo = false;
				this.product = {...product};
                this.Ciudades(this.product.estado);
                this.product.sexo =  this.$store.state.sexos.filter(val => val.id == this.product.sexo)[0];
                this.product.estado =  this.$store.state.estados.filter(val => val.id == this.product.estado)[0];
                this.product.ciudad =  this.ciudades.filter(val => val.id == this.product.ciudad)[0];
                this.productDialog = true;
			},
			EditarSueldo(product) {
				this.nuevo = false;
				this.product = {...product};
				if(this.product.ciclo){
					this.product.ciclo =  this.ciclos.filter(val => val.id == this.product.ciclo)[0];
				}
				if(this.product.moneda){
					this.product.moneda = this.$store.state.monedas.filter(val => val.id == this.product.moneda)[0];
				}
                this.productDialog2 = true;
			},
			Guardar() {
				if (this.product.apellido == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Apellido', life: 5000});
				} else if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Nombre', life: 5000});
				} else if (this.product.rif == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Rif', life: 5000});
				} else if (this.product.celular == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Celular', life: 5000});
				} else if (this.product.fechnac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Fecha de Nacimiento', life: 5000});
				} else if (this.product.direccion == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Direccion Detallada', life: 5000});
				} else if (this.product.cedula == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Cédula', life: 5000});
				} else if (this.product.correo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Correo', life: 5000});
				} else if (this.product.estado == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Estado', life: 5000});
				} else if (this.product.ciudad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Ciudad', life: 5000});
				} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
					test(this.product.correo)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
				} else {
					var caso = 'UPD';
					if (this.nuevo) {caso = 'INS';}
					Consulta.Procesar(caso,{
						product: this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.Mostrar();
							this.productDialog = false;
							this.product = {};
						}
					});
				}
			},
			GuardarSueldo() {
				this.Sueldo();
				Consulta.Procesar('Sueldo',{
					product: this.product,
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {
						this.Mostrar();
						this.productDialog2 = false;
						this.product = {};
					}
				});
			},
			Confirmar(product) {
				this.product = product;
				this.deleteDialog = true;
			},
			Eliminar() {
				Consulta.Procesar('DEL',{
					product: 	this.product
				}).then(response => {
					this.$store.commit('Loading');
                    //this.$store.state.error = response;
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {this.Mostrar();}
				});
				this.deleteDialog = false;
				this.product = {};
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
